import axios from "axios";


const http = axios.create({
    baseURL: 'https://rj90d0hm.api.sanity.io/v2021-06-07/data/query/production',
});

export const getAllServicesHomePage = async () => {
    const {data} = await http.get('',{params:{query:"*[_type=='service'] {'slug':slug.current,category,title,pre,'picture':mainImage.asset->url}"}})

    return data.result || [];
};

export const getServiceDetail = async (slug) => {
    const {data} = await http.get('',{params:{query:`*[_type=='service' && slug.current=='${slug}'] {body,'slug':slug.current,category,title,pre,'picture':bigImage.asset->url}`}})
    return data.result[0] || {};

}
export const getPostDetail = async (slug) => {
    const {data} = await http.get('',{params:{query:`*[_type=='blog' && slug.current=='${slug}'] {_createdAt,body,'slug':slug.current,category,title,pre,'picture':bigImage.asset->url,'author': author->{name, 'picture': image.asset->url,title}}`}})
    return data.result[0] || {};
}

export const getTestimonials = async () => {
    const {data} = await http.get('',{params:{query:`*[_type=='testimonial'] {content,name}`}})
    return data.result || {};
}

export const getAllPostsForHomePage = async () => {
    const {data} = await http.get('',{params:{query:`*[_type=='blog'] {body,'slug':slug.current,category,title,pre,'picture':smallImage.asset->url,'author': author->{name, 'picture': image.asset->url,title},}`}})
    return data.result || [];
}

export const getAllPsychologistsForHomePage = async () => {
    const {data} = await http.get('',{params:{query:`*[_type=='psychologist'] {'slug':slug.current,'id':_id,page,definition,'picture':smallImage.asset->url,'fullName': firstName + ' ' +  lastName}`}})
    return data.result || [];
}

export const getPsychologist = async (slug) => {
    const {data} = await http.get('',{params:{query:`*[_type=='psychologist' && slug.current=='${slug}'] {slug,'id':_id,pre,body,definition,others,'fullName': firstName + ' ' +  lastName,firstName,lastName,'slug':slug.current,'picture':bigImage.asset->url}`}})
    return data.result[0] || {};
}

