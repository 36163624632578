<template>
  <div>
    <!-- Start Portfolio Activation  -->
    <VueSlickCarousel
      v-bind="settings"
      class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"
    >
      <!-- Start Single Portfolio  -->
      <div class="im_portfolio" v-for="(item, i) in posts" :key="i">
        <div class="thumbnail_inner">
          <div class="thumbnailss">
            <a href="#">
              <img class="w-100" :src="item.picture" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">
                <router-link :to="`/terahayat-blog/${item.slug}`">{{
                  item.pre
                }}</router-link>
              </div>
              <h4 class="heading-title">
                <router-link :to="`/terahayat-blog/${item.slug}`">{{
                  item.title
                }}</router-link>
              </h4>
            </div>
            <div class="portfolio_hover">
              <p>{{ item.author.name }}</p>
            </div>
          </div>
        </div>
        <router-link
          class="transparent_link"
          :to="`/terahayat-blog/${item.slug}`"
        ></router-link>
      </div>
      <!-- End Single Portfolio  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    props:{
      posts:{
        type:Array,
        default:() => []
      }
    },
    components: {
      VueSlickCarousel,
    },
    data() {
      return {
        settings: {
          "dots": true,
          "focusOnSelect": true,
          "infinite": true,
          "speed": 500,
          "slidesToShow": 4,
          "slidesToScroll": 3,
          "touchThreshold": 5,
          arrows: true,

          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: true,

              },
            },
            {
              breakpoint: 1263,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: true,

              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: true,

              },
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: true,

              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
              },
            },
          ],
        },
      };
    },
  };
</script>
