import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import LandingInterior from '../views/all-home-version/LandingInterior.vue'
import Contact from "../views/Contact";
import BlogDetails from "../views/blog/BlogDetails";
import ServiceDetails from "../views/service/ServiceDetails";
import PortfolioDetail from "../views/portfolio/PortfolioDetail";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LandingInterior',
        meta: {
            title: 'Terahayat - Psikoloji Danışmanlık'
        },
        component: LandingInterior
    },
    {
        path: '/:slug',
        name: 'Portfolio',
        meta: {
            title: tag => `Terahayat - ${tag}`
        },
        component: PortfolioDetail
    },
    {
        path: '/terahayat-blog/:slug',
        name: 'Terahayat-Blog',
        meta: {
            title: 'Terahayat Blog'
        },
        component: BlogDetails
    },
    {
        path: '/terahayat-servisler/:slug',
        name: 'Terahayat-Services',
        meta: {
            title: 'Terahayat Servisler'
        },
        component: ServiceDetails
    },
    {
        path: '/terahayat-iletisim',
        name: 'Contact',
        meta: {
            title: 'Terahayat - Psikoloji Danışmanlık'
        },
        component: Contact
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: '404 - Terahayat - Psikoloji Danışmanlık'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    if (to.name === 'Portfolio') {
        const {slug} = to.params
        const tag = slug.replace('-',' ')
        document.title = to.meta.title(tag)
    } else {
        document.title = to.meta.title
    }
    next()
})

export default router
