<template>
  <div>
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image" :style="{backgroundImage:`url(${service.picture})`}"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">{{ service.title }}</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col cols="12">
                    <BlockContent :blocks="service.body || []"/>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import feather from "feather-icons";
  import {getServiceDetail} from "../../service";

  export default {
    data() {
      return {
        service:{},
      };
    },
    async mounted(){
      const {slug} = this.$route.params
      this.service = await getServiceDetail(slug)

    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
