<template>

    <div v-if="psychologist">

      <!-- Start Slider Area  -->
      <div class="rn-slider-area personal-portfolio-slider-area">
        <div
            class="justify-center slide slider_fixed_height slider-style-3 d-flex align-center bg_image--empty white-overlay-mobile"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="text-left inner">
                <span class="theme-gradient font-500"
                >{{ psychologist.definition }}</span
                >
                  <h1 class="heading-title">Merhaba, Ben <span>{{ psychologist.firstName }}.</span></h1>
                  <p class="description">
                    <BlockContent :blocks="psychologist.pre || []"/>
                  </p>
                  <div class="slide-btn">
                    <scrollactive
                        active-class="v-btn--active"
                        bezier-easing-value=".5,0,.35,1"
                        :offset="71"
                    >
                      <a class="btn-default scrollactive-item" href="#contact"
                      ><i class="fa fa-phone"></i> Hemen Ara</a
                      >
                    </scrollactive>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <!-- End Slider Area -->

      <!-- Start About Area  -->
      <div class="about-area ptb--120 bg_color--1">
        <div class="about-wrapper">
          <div class="container">
            <AboutFive>
              <template v-slot:image>
                <img
                    class="w-100"
                    :src="psychologist.picture"
                    :alt="psychologist.fullName"
                />
              </template>
              <template v-slot:about>
                <span class="subtitle">{{ psychologist.definition }}</span>
                <h2 class="heading-title">{{ psychologist.fullName }}</h2>
                <BlockContent :blocks="psychologist.body || []"/>
              </template>
            </AboutFive>
          </div>
        </div>
      </div>
      <!-- Start About Area  -->

      <!-- Start Brand Area -->
      <div class="rn-brand-area pb--120 bg_color--1" id="educations">
        <v-container>
          <v-row v-for="(other,i) in psychologist.others" :key="i">
            <v-col lg="12">
              <div class="section-title text-center mb--25 mb_sm--0 educations">
                <span class="subtitle">{{ other.desc }}</span>
                <h2 class="heading-title">{{ other.title }}</h2>
                <BlockContent :blocks="other.content"/>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else class="ptb--120">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader v-for="index in 2" :key="index"
                               class="mt-2"
                type="heading"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                width="150px"
                type="button"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col cols="4">
            <v-skeleton-loader
                type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="8">
            <v-skeleton-loader v-for="index in 3" :key="index"
                type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col cols="12">
            <v-skeleton-loader v-for="index in 2" :key="index"
                               type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>

      </v-container>
    </div>
</template>

<script>
import feather from "feather-icons";
import AboutFive from "../../components/about/AboutFive";
import {getPsychologist} from "../../service";

export default {
  components: {
    AboutFive,
  },
  data() {
    return {
      psychologist: null,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      }
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
  async mounted() {
    const {slug} = this.$route.params
    this.psychologist = await getPsychologist(slug)
  }
};
</script>

<style lang="scss" scoped>
.bg_image--empty {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .30) 0%, rgba(255, 255, 255, .1) 100%), url("../../assets/images/portfolio/empty-bg.png");
}

.no-spacer-inside-brand {
  .row.mt--20 {
    margin-top: 0 !important;
  }
}

.educations li {
  text-align: left;
}

.educations li {
  list-style: none;
}

.educations li::before {
  content: '◉';
  color: var(--color-primary);
  margin-right: 5px;
}
</style>
