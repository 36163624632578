<template>
  <div>
    <div
        class="rn-page-title-area pt--120 pb--190 bg_image" :style="{backgroundImage:`url(${post.picture})`}"
        data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                {{ post.title }}
              </h2>
              <ul class="justify-center blog-meta d-flex align-center">
                <li class="d-flex">
                  <span v-html="iconSvg('user')"></span>{{ post.author.name }}
                </li>
                <li class="d-flex">
                  <span v-html="iconSvg('clock')"></span>{{ new Date(post._createdAt).toLocaleDateString('tr-TR',{year:'numeric',month:'short',day:'2-digit'}) }}
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--110 pb--70 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <BlockContent :blocks="post.body || []"/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->


  </div>
</template>

<script>
import feather from "feather-icons";
import marked from "marked";
import {getPostDetail} from "../../service";

export default {
  data() {
    return {
      post: {
        author: {}
      }
    }
  },
  async mounted() {
    const {slug} = this.$route.params
    this.post = await getPostDetail(slug)
  },
  computed: {
    markedText() {
      return marked('## world')
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    onSubmit() {
      console.log(this.formData);
    },
  },
};
</script>
