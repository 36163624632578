<template>
  <v-row>
    <!-- Start Single Team Area  -->
    <v-col
      lg="4"
      md="5"
      sm="6"
      :offset-lg="i === teamContent.length ? 2:0"
      cols="12"
      v-for="(person,i) in teamContent"
      :key="person.id"
    >
      <div class="team team-style--bottom">
        <div class="thumbnail">
          <img :src="person.picture" alt="Team Images" />
        </div>
        <div class="content">
          <h4 class="heading-title">
            {{ person.fullName }}
          </h4>
          <p class="designation">{{ person.definition }}</p>
        </div>
        <SocialIcon />
        <router-link v-if="person && person.slug"
            class="transparent_link"
            :to='person.slug'
        ></router-link>
      </div>
    </v-col>
    <!-- End Single Team Area  -->
  </v-row>
</template>

<script>
  import SocialIcon from "../../components/social/Social";
  import {getAllPsychologistsForHomePage} from "../../service";
  export default {
    components: {
      SocialIcon,
    },
    data() {
      return {
        teamContent: [],
      };
    },
    async mounted() {
      this.teamContent = await getAllPsychologistsForHomePage()
    }
  };
</script>
