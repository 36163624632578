<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="rn-testimonial-content">
        <v-row align="center">
            <div class="text-left section-title mb--30" style="z-index: 2">
              <span class="subtitle">Yorumlar</span>
              <h2 class="heading-title">Danışanların Gözünden TeraHayat</h2>
            </div>
          <v-col md="12" sm="12" cols="12" class="tesimonail-conent-inner" >
            <v-carousel hide-delimiters  :height="'100%'">
              <v-carousel-item v-for="(item,i) in testimonials" :key="i">
                <v-card >
                  <v-card-text>
                    <div class="inner">
                      <p class="text-h6">
                        {{ item.content }}
                      </p>
                    </div>
                    <div class="author-info">
                      <h6>
                        <span>{{ item.name }}</span>
                      </h6>
                    </div>
                  </v-card-text>
                </v-card>
              </v-carousel-item>
            </v-carousel>
            <!-- End .v-tabs-items -->
          </v-col>
        </v-row>
      </div>
      <!-- End .rn-testimonial-content -->
    </v-col>
  </v-row>
</template>

<script>
import {getTestimonials} from "../../service";

export default {
    data() {
      return {
        tab: 1,
        testimonials: [],
      };
    },
    methods:{
      updateTab(value) {
        const tabValue = this.tab + value
        if(tabValue === 0) {
          return this.tab = this.testimonials.length -1
        } else if(tabValue === this.testimonials.length) {
          return this.tab = 0
        }
        this.tab = tabValue
      }
    },
  async mounted() {
      this.testimonials = await getTestimonials();
  }
};
</script>
<style scoped lang="scss">
button.navigations {
   padding: 0;
  border:none;
  background:none;
  position: absolute;
  z-index: 1;
  transform:translateY(120px);
  & i:hover:not(:active) {
  transition: all 200ms linear;
    opacity: .5;
    transform: scale(1.1);
  }
  &-left {
    left:10px;
  }
  &-right {
    right:10px;
  }
}
</style>
