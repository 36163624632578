<template>
  <div>

    <!-- Start Contact Address Area  -->
    <div class="rn-contact-top-area ptb--120 bg_color--5">
      <v-container>
        <div class="text-center section-title mb--30">
          <span class="subtitle">Bir telefon kadar uzağınızdayız</span>
          <h2 class="heading-title">İletişim Kanallarımız</h2>
        </div>
        <ContactAddress />
      </v-container>
    </div>
    <!-- End Contact Address Area  -->

    <!-- Start Contact Area  -->
<!--    <div class="rn-contact-page ptb&#45;&#45;120 bg_color&#45;&#45;1">-->
<!--      <div class="contact-form&#45;&#45;1">-->
<!--        <v-container>-->
<!--          <Contact>-->
<!--            <img-->
<!--              slot="contact-img"-->
<!--              src="../assets/images/about/about-12.jpg"-->
<!--              alt="contact images"-->
<!--            />-->
<!--          </Contact>-->
<!--        </v-container>-->
<!--      </div>-->
<!--    </div>-->
    <!-- End Contact Area  -->

    <!-- Start Google MAp -->
    <div class="google-map rn-contact-map-area position-relative">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.455541352602!2d28.912172215879735!3d41.05903407929565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab10cbf06e92b%3A0x5c92268f76d2b449!2sTeraHayat%20Psikolojik%20Dan%C4%B1%C5%9Fmanl%C4%B1k!5e0!3m2!1str!2str!4v1627734872567!5m2!1str!2str"
          ></iframe
          ><a href="https://embedgooglemap.net/maps/70"></a><br />
          <a href="https://www.embedgooglemap.net">google map code embed</a>
        </div>
      </div>
    </div>
    <!-- End Google MAp -->

    <!-- Start Brand Area -->
    <!-- End Brand Area -->
  </div>
</template>

<script>
  import ContactAddress from "../components/contact/ContactAddress";

  export default {
    components:{
      ContactAddress
    },
    data() {
      return {
        logo: require("../assets/images/logo/logo.png"),
      };
    },
  };
</script>

<style lang="scss">
.bg_image--15 {
  background-image: url("../assets/images/bg/contact.webp") !important;
}
  .mapouter {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;
    @media only screen and (max-width: 1199px) {
      height: 500px;
    }
    @media only screen and (max-width: 992px) {
      height: 420px;
    }
  }
  .gmap_canvas iframe {
    width: 100%;
    height: 100%;
  }
</style>
