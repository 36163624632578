<template>
  <v-row class="row--35">
    <v-col md="5" sm="12" cols="12">
      <div class="thumbnail">
       <slot name="image"></slot>
      </div>
    </v-col>
    <v-col md="7" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <slot name="about">

          </slot>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
