<template>
  <div>
    <!-- Start Header Area -->
<!--    <HeaderOnePageThree>-->
<!--      <img slot="logo" :src="logoLight" class="logo-light" />-->
<!--      <img slot="logo-dark" :src="logoDark" class="logo-dark" />-->
<!--    </HeaderOnePageThree>-->
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="im_modern_slider" id="home">
      <div
        class="justify-center slide slide-style-2 slider-paralax d-flex align-center bg_image bg_image--29"
        data-black-overlay="5"
      >
        <Banner>
          <h1 slot="heading-title" class="heading-title">
            TeraHayat
          </h1>
          <p slot="description" class="description">
            Her zaman bir yol bulunur
          </p>
           <a slot="button" class="btn-default" href="tel:+905364462527">
             <i class="fa fa-phone"></i>  Randevu Al
            </a>
        </Banner>
      </div>
    </div>
    <!-- End Slider Area  -->

    <!-- Start Service Area  -->
    <div
      class="service-area creative-service-wrapper ptb--120 bg_color--1"
      id="service"
    >
      <v-container>
        <v-row>
          <v-col lg="8">
            <div class="feature-area">
              <div class="feature-area">
                <span class="subtitle theme-gradient">Bazen çığlık atabilirsin ama asla pes etme</span>
                <h3 class="heading-title mt--20 fontWeight500 lineheight--1-8">
                  Her zaman sizi doğru yöne yönlendiren işaretler vardır ve her zaman çözümler için fikirler vardır. Doğru yönde bir adım atın, randevu için bugün bize ulaşın.
                </h3>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <ServiceSix class="interior-services" /> -->
      </v-container>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Portfolio Area -->
    <div class="portfolio-area pb--120 bg_color--1">
      <v-container>
        <PortfolioTen :services="services"/>
      </v-container>
    </div>
    <!-- End Portfolio Area -->

    <!-- End Counterup Area -->

    <!-- Start Content Box  -->

    <!-- Start Content Box -->

    <!-- Start team Area  -->
    <div class="rn-team-wrapper pt--120 bg_color--1" id="team">
      <v-container>
        <v-row>
          <v-col md="8" cols="12">
            <div class="text-left section-title mb--30">
              <span class="subtitle">Psikologlar</span>
              <h2 class="heading-title">TeraHayat Psikologlar</h2>
              <p class="description">
                Deneyimli psikoterapistlerden oluşan bir ekibiz. Kendinizi daha iyi hissetmenize ve biraz mutluluk bulmanıza yardımcı olabiliriz. Endişeli, bunalmış veya basitçe kaybolmuş hissedebileceğinizi biliyoruz. Yardım etmek için buradayız.
              </p>
            </div>
          </v-col>
        </v-row>
        <TeamFour />
      </v-container>
    </div>
    <!-- End team Area  -->

    <!-- Start Testimonial Area  -->
    <div class="rn-testimonial-area bg_color--1 pt--120" id="tesimonial">
      <v-container>
        <Testimonial />
      </v-container>
    </div>
    <!-- Start Testimonial Area  -->



    <!-- Start Portfolio Area -->
    <div class="portfolio-area ptb--120 bg_color--1" id="blog">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="6">
              <div class="text-left section-title mb--30">
                <span class="subtitle">Terahayat Blog Yazıları</span>
                <h2 class="heading-title">Blog</h2>
                <p class="description">
                  İnsanların nasıl düşündüklerini anlamanın en iyi yolu yazılarından geçer. Blog sayfası, kendinizi keşfetme yolculuğunuzda yardım, ilham ve rehberlik bulmak için harika bir yerdir.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <PortfolioEight v-if="posts && !!posts.length" :posts="posts"/>
      </div>
    </div>
    <!-- End Portfolio Area -->
    <!-- Start Contact Area  -->
    <div class="rn-testimonial-area bg_color--1 pt--120 mb--100" id="contact">
      <v-container>
        <Contact />
      </v-container>
    </div>
    <!-- Start Contact Area  -->

    <!-- Start Footer Area  -->
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Banner from "../../components/slider/Banner";
  import PortfolioTen from "../../components/portfolio/PortfolioTen";
  import TeamFour from "../../components/team/TeamFour";
  import Testimonial from "../../components/testimonial/Testimonial";
  import PortfolioEight from "../../components/portfolio/PortfolioEight";
  import Contact from "../../views/Contact";
  import {getAllPostsForHomePage, getAllServicesHomePage} from "../../service";

  export default {
    components: {
      Contact,
      Banner,
      PortfolioTen,
      PortfolioEight,
      TeamFour,
      Testimonial,
    },
    data() {
      return {
        logoLight: require("../../assets/images/logo/logo.png"),
        logoDark: require("../../assets/images/logo/logo.png"),
        services:[],
        posts:[]
      };
    },
    async mounted() {
      this.services = await getAllServicesHomePage()
      this.posts = await getAllPostsForHomePage()
    }
  };
</script>
