<template>
  <v-row class="mt_dec--30">
    <!-- Start Single Portfolio  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(item, i) in services"
      :key="i"
    >
      <div class="im_portfolio">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <a href="#">
              <img class="w-100" :src="item.picture" alt="item.title" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">
                <router-link to="#">{{
                  item.category
                }}</router-link>
              </div>
              <h4 class="heading-title">
                <router-link to="#">{{
                  item.title
                }}</router-link>
              </h4>
            </div>
            <div class="portfolio_hover">
              <p>{{ item.pre }}</p>
            </div>
          </div>
        </div>
        <router-link
          class="transparent_link"
          :to='`/terahayat-servisler/${item.slug}`'
        ></router-link>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    props:['services'],
  };
</script>
