<template>
  <v-row>
    <!-- Start Single Address  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(address, i) in addressContent"
      :key="i"
    >
      <div class="rn-address" :class="address.isAddress">
        <div class="icon" v-html="iconSvg(address.icon)"></div>
        <div class="inner">
          <h4 class="heading-title">{{ address.title }}</h4>
          <p>
            <a :href="address.to1">{{ address.info1 }}</a>
          </p>
          <p>
            <a :href="address.to2">{{ address.info2 }}</a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        addressContent: [
          {
            icon: "headphones",
            title: "TeraHayat Telefon Numaramız",
            info1: "+90 536 446 25 27",
            to1: "tel:+90 536 446 25 27",
          },
          {
            icon: "mail",
            title: "TeraHayat Mail Adresimiz",
            info1: "terahayatpsikoloji@gmail.com",
            to1: "mailto:terahayatpsikoloji@gmail.com",
          },
          {
            icon: "map",
            title: "TeraHayat Lokasyonumuz",
            info1: "Bağlarbaşı Mahallesi, Güllü Sokak No:1 D: 1",
            info2: "Gaziosmanpaşa",
            isAddress: "addres-last",
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style lang="scss">
  .addres-last {
    p {
      pointer-events: none;
    }
  }
</style>
